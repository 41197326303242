<template>
  <section class="border p-4 shadow-sm rounded bg-white">
    <div
      v-if="pending"
      id="pending"
      class="d-flex flex-column justify-content-center align-items-center align-content-center"
    >
      <h1 class="text-danger">LOADING</h1>
      <b-spinner variant="danger" size="lg" />
    </div>
    <b-container v-else fluid="fluid" class="mt-2">
      <b-row>
        <b-col>
          <div class="d-flex flex-column">
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <h2>{{ ad.title }}</h2>
              <div class="align-self-end">
                <h5>REG_DATE: {{ new Date(ad.regDate).toLocaleString() }}</h5>
                <h5 v-if="ad.startDate">
                  START_DATE: {{ new Date(ad.startDate).toLocaleString() }}
                </h5>
              </div>
            </div>
            <div class="border p-4">
              <div v-if="adMedia.length > 0">
                <div v-for="i of adMedia" v-bind:key="i._id" class="mb-3">
                  <div>{{ i.title }}</div>
                  <div v-if="i.type === 'video'">
                    <video v-if="i.video" controls :poster="i.video.gif">
                      <source type="video/mp4" :src="i.video.path" />
                    </video>
                    <div v-else>
                      <h5>영상 없음</h5>
                    </div>
                  </div>
                  <div v-else-if="i.type === 'image'">
                    <div
                      v-if="i.image"
                      class="thumb"
                      :style="{ 'background-image': `url('${i.image.gif}')` }"
                    />
                    <div v-else>
                      <h5>이미지 없음</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div id="edit-form">
                <b-form v-on:submit.prevent="save">
                  <div class="d-flex flex-column">
                    <div
                      class="p-1 d-flex justify-content-between align-items-center border-bottom"
                    >
                      <div class="mr-2">TITLE</div>
                      <div class="flex-grow-1">
                        <b-form-input
                          v-model="ad.title"
                          size="sm"
                          placeholder="TITLE"
                        />
                      </div>
                    </div>
                    <div
                      class="p-1 d-flex justify-content-between align-items-center border-bottom"
                    >
                      <div class="mr-2">DESC</div>
                      <div class="flex-grow-1">
                        <b-form-textarea
                          v-model="ad.desc"
                          size="sm"
                          placeholder="DESC"
                        />
                      </div>
                    </div>
                    <div
                      class="p-1 d-flex justify-content-between align-items-center border-bottom"
                    >
                      <div class="mr-2">GENDER</div>
                      <div class="flex-grow-1 d-flex">
                        <div
                          v-for="code in codes.genderCodes"
                          :key="`age-${code.value}`"
                          class="mx-2"
                        >
                          <b-badge
                            :variant="
                              code.value & ad.gender ? 'success' : 'secondary'
                            "
                          >
                            {{ code.text }}
                          </b-badge>
                        </div>
                      </div>
                    </div>
                    <div
                      class="p-1 d-flex justify-content-between align-items-center border-bottom"
                    >
                      <div class="mr-2">AGE</div>
                      <div class="flex-grow-1 d-flex">
                        <div
                          v-for="code in codes.ageCodes"
                          :key="`age-${code.value}`"
                          class="mx-2"
                        >
                          <b-badge
                            :variant="
                              code.value & ad.age ? 'success' : 'secondary'
                            "
                          >
                            {{ code.text }}
                          </b-badge>
                        </div>
                      </div>
                    </div>
                    <div
                      class="p-1 d-flex justify-content-between align-items-center border-bottom"
                    >
                      <div class="mr-2">REGION</div>
                      <div class="flex-grow-1 d-flex">
                        <div
                          v-for="code in codes.regionCodes"
                          :key="`age-${code.value}`"
                          class="mx-2"
                        >
                          <b-badge
                            :variant="
                              code.value & ad.region ? 'success' : 'secondary'
                            "
                          >
                            {{ code.text }}
                          </b-badge>
                        </div>
                      </div>
                    </div>
                    <div
                      class="p-1 d-flex justify-content-between align-items-center border-bottom"
                    >
                      <div class="mr-2">COUNT</div>
                      <div class="flex-grow-1 d-flex">
                        <div
                          v-for="code in codes.answerCountCodes"
                          :key="`age-${code.value}`"
                          class="mx-2"
                        >
                          <b-badge
                            :variant="
                              code.value === ad.answerCount
                                ? 'success'
                                : 'secondary'
                            "
                          >
                            {{ code.text }}
                          </b-badge>
                        </div>
                      </div>
                    </div>
                    <div
                      class="p-1 d-flex justify-content-between align-items-center border-bottom"
                    >
                      <div class="mr-2">INDUSTRY</div>
                      <div class="flex-grow-1 d-flex">
                        {{ ad.industry }}
                        <!--                        <div-->
                        <!--                            v-for="code in codes.industryCodes"-->
                        <!--                            :key="`age-${code.value}`"-->
                        <!--                            class="mx-2"-->
                        <!--                        >-->
                        <!--                          <b-badge-->
                        <!--                              :variant="code.value === ad.industry ? 'success' : 'secondary'"-->
                        <!--                          >-->
                        <!--                            {{ code.text }}-->
                        <!--                          </b-badge>-->
                        <!--                        </div>-->
                      </div>
                    </div>
                    <div
                      class="p-1 d-flex justify-content-between align-items-center border-bottom"
                    >
                      <div class="mr-2">STATUS</div>
                      <div class="flex-grow-1 d-flex">
                        <b-form-select
                          size="sm"
                          :options="codes.statusCodes"
                          v-model="ad.status"
                        />
                      </div>
                    </div>
                    <div
                      class="p-1 d-flex justify-content-between align-items-center"
                    >
                      <div class="mr-2">SURVEY STATUS</div>
                      <div class="flex-grow-1 d-flex">
                        <b-form-select
                          size="sm"
                          :options="codes.surveyStatusCodes"
                          v-model="ad.surveyStatus"
                        />
                      </div>
                    </div>
                    <div class="p-1 border-bottom">
                      <span
                        v-if="ad.validationStatus !== 'end'"
                        style="color: red"
                        >등록된 광고 미디어 검증이 모두 완료되지 않아 SURVEY
                        STATUS 를 START 로 변경할 수 없습니다.<BR /> 광고 미디어
                        검증이 모두 완료되면 변경 할 수 있습니다.</span
                      >
                    </div>

                    <!-- ad 기준으로 확인이 필요함 -->
                    <ad-validation title="전체" :target="ad" />
                    <ad-validation
                      v-for="a of adMedia"
                      v-bind:key="a._id"
                      :title="a.title"
                      :target="a"
                    />

                    <div
                      class="p-1 d-flex justify-content-between align-items-center border-bottom"
                    >
                      <div class="mr-2">현황</div>
                      <div class="flex-grow-1 d-flex">
                        <a :href="quotaUrl" target="_blank">
                          {{ quotaUrl }}
                        </a>
                      </div>
                    </div>
                    <div
                      class="p-1 d-flex justify-content-between align-items-center border-bottom"
                    >
                      <div class="mr-2 text-left">설문 테스트 링크</div>
                      <div class="flex-grow-1 d-flex text-left">
                        <a :href="testSurveyUrl" target="_blank">
                          {{ testSurveyUrl }}
                        </a>
                      </div>
                    </div>
                    <div
                      class="p-1 d-flex justify-content-between align-items-center border-bottom"
                    >
                      <div class="mr-2 text-left">설문 TEST 데이터 초기화</div>
                      <div class="flex-grow-1 d-flex text-left">
                        <button
                          type="button"
                          class="btn btn-danger"
                          v-on:click="surveyTestDataReset"
                        >
                          초기화
                        </button>
                        <span class="ml-3"
                          >(SURVEY STATUS가 READY 상태일 때 삭제가
                          가능합니다.)</span
                        >
                      </div>
                    </div>
                    <div
                      class="p-1 d-flex justify-content-between align-items-center border-bottom"
                    >
                      <div class="mr-2 text-left">대시보드 링크</div>
                      <div class="flex-grow-1 d-flex text-left pt-2">
                        <div v-if="ad.status === 'complete'">
                          <div v-for="a of adMedia"
                               v-bind:key="`dashboard_${a._id}`">
                            ({{a.title}}) <a

                              :href="a.dashboardUrl"
                              target="_blank"
                            >
                              {{ a.dashboardUrl }}
                            </a>
                            <hr/>
                          </div>
                        </div>
                        <div v-else class="pb-2">
                        <span>
                            : 프로젝트가 완료되면 링크가 생성됩니다.</span
                        >
                        </div>
                      </div>
                    </div>
                    <!--                    <div class = "border-bottom">
                      설문 테스트시 링크에 [UID]를 임의 값으로 변경하여 테스트 해주시기 바랍니다. (ex: [UID] = TEST01)
                    </div>-->
                    <div
                      class="p-1 d-flex justify-content-between align-items-center border-bottom"
                    >
                      <div class="mr-2">패널 사용</div>
                      <div class="flex-grow-1 d-flex">
                        <b-form-select
                          :options="codes.panelChannelCodes"
                          v-model="ad.panelChannels"
                          multiple
                          :disabled="ad.surveyStatus !== 'ready'"
                        />
                      </div>
                    </div>

                    <div
                      v-for="a of adMedia"
                      v-bind:key="`validationLabels_${a._id}`"
                      class="border-bottom"
                    >
                      <div
                        v-if="a.validationLabels"
                        class="p-1 d-flex justify-content-between align-items-center"
                      >
                        <div class="mr-2">
                          <span class="font-weight-bold">({{ a.title }})</span
                          ><br />
                          LABELS
                        </div>
                        <div
                          class="border-left d-flex flex-column justify-content-start flex-grow-1"
                        >
                          <div class="border-bottom text-left">
                            <div class="p-1 border-bottom">
                              <b-badge variant="primary">유해 레이블</b-badge>
                            </div>
                            <div class="d-flex flex-wrap py-2">
                              <div
                                v-for="(label, idx) in a.validationLabels
                                  .contentModerationLabels"
                                :key="`moderation-label-${label}-${idx}`"
                                class="text-left m-1"
                              >
                                <b-badge
                                  :variant="
                                    codes.detectModerationKeyword.find(
                                      (keyword) => keyword === label
                                    )
                                      ? 'danger'
                                      : 'secondary'
                                  "
                                >
                                  {{ label }}
                                </b-badge>
                              </div>
                            </div>
                          </div>
                          <div class="text-left">
                            <div class="p-1 border-bottom">
                              <b-badge variant="primary">키워드</b-badge>
                            </div>
                            <div class="d-flex flex-wrap py-2">
                              <div
                                v-for="(label, idx) in a.validationLabels
                                  .labels"
                                :key="`label-${label}-${idx}`"
                                class="text-left m-1"
                              >
                                <b-badge
                                  :variant="
                                    codes.detectPersonKeyword.find(
                                      (keyword) => keyword === label
                                    )
                                      ? 'danger'
                                      : 'secondary'
                                  "
                                >
                                  {{ label }}
                                </b-badge>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="p-1 border-bottom"
                      v-if="questionAdds.length > 0"
                    >
                      <div class="flex-grow-1 d-flex mt-3">DIV 문항정보</div>

                      <div
                        class="flex-grow-1 mt-3 text-left"
                        v-for="(item, index) in questionAdds"
                        :key="`questions+_${index}`"
                      >
                        <b-alert show class="w-100 mb-1"
                          ><b>{{ item.questionNumber }}</b
                          >. {{ item.tableLabel }}</b-alert
                        >
                        <b-badge variant="success mr-1">
                          {{
                            item.questionType === "check" ? "복수" : "단일"
                          }}응답
                        </b-badge>
                        <template v-if="item.hasOwnProperty('etcNone')">
                          <b-badge
                            variant="success mr-1"
                            v-if="item.etcNone.indexOf('etc') > -1"
                          >
                            기타 항목
                          </b-badge>
                          <b-badge
                            variant="success mr-1"
                            v-if="item.etcNone.indexOf('none') > -1"
                          >
                            없음 항목
                          </b-badge>
                        </template>
                        <b-badge variant="success mr-1">
                          {{
                            item.location === "1"
                              ? "AD Play 이전"
                              : item.location === "2"
                              ? "AD Play 이후"
                              : "AD 평가 이후"
                          }}응답
                        </b-badge>

                        <b-list-group
                          class="pl-2 pr-2 w-100"
                          v-for="(item2, index2) in item.examples"
                          :key="`ex_${index2}`"
                        >
                          <b-list-group-item class="mt-1"
                            >{{ item2.value }}.
                            {{ item2.text }}</b-list-group-item
                          >
                        </b-list-group>
                        <b-list-group
                          class="pl-2 pr-2 w-100"
                          v-for="(item2, index2) in item.valuesEtcNone"
                          :key="`etcNone_${index2}`"
                        >
                          <b-list-group-item class="mt-1"
                            >{{ item2.value }}.
                            {{ item2.text }}</b-list-group-item
                          >
                        </b-list-group>
                      </div>
                    </div>

                    <div
                        class="p-1 py-3 d-flex justify-content-between align-items-center border-bottom"
                    >
                      <div class="mr-3">안면인식 사용 여부</div>
                      <div class="flex-grow-1 d-flex">
                        <b-form-checkbox
                            v-model="ad.facialRecognition"
                        >
                          {{ ad.facialRecognition ? '사용' : '사용 안함' }}
                        </b-form-checkbox>
                      </div>
                    </div>

                    <div class="p-1 mt-1">
                      <b-button
                        @click="save"
                        typ="submit"
                        squared
                        block
                        size="sm"
                        :disabled="pending"
                      >
                        <font-awesome-icon icon="save" />
                      </b-button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <hr />
  </section>
</template>

<script>
import adValidation from "../../components/ad/validation";
export default {
  name: "LIST_ONE",
  components: {
    adValidation,
  },
  data: () => ({
    _id: null,
    pending: false,
    ad: {
      _id: null,
      seq: null,
      title: null,
      desc: null,
      category: null,
      answerCount: null,
      gender: null,
      age: null,
      region: null,
      industry: null,
      industryQuestionId: null,
      video: null,
      image: null,
      regUser: null,
      regUserName: null,
      regDate: null,
      use: null,
      status: null,
      validationStatus: null,
      harmful: null,
      character: null,
      surveyStatus: null,
      myPick: null,
      type: null,
      batch: null,
      fieldWork: null,
      completeEndDate: null,
      realEndDate: null,
      startDate: null,
      validationStartDate: null,
      validationLabels: null,
      facialRecognition: false
    },
    adMedia: [],
    codes: {
      ageCodes: [],
      answerCountCodes: [],
      genderCodes: [],
      industryCodes: [],
      regionCodes: [],
      statusCodes: [],
      surveyStatusCodes: [],
      validationStatusCodes: [],
      panelChannelCodes: [],
      detectPersonKeyword: [],
      detectModerationKeyword: [],
    },
    quotaUrl: null,
    testSurveyUrl: null,
    questionAdds: [],
    dashboardUrl: null,
  }),
  created() {
    (async () => {
      const { _id } = this.$route.params;
      this._id = _id;
      await this.load();
    })();
  },
  computed: {
    mediaUrl() {
      if (this.ad.type === "video" && this.ad.video) return this.ad.video.path;
      if (this.ad.type === "image" && this.ad.image) return this.ad.image.gif;
      return null;
    },
    thumb() {
      if (this.ad.type === "video" && this.ad.video) return this.ad.video.gif;
      return null;
    },
    validationLabels() {
      if (this.ad && this.ad.validationLabels) {
        const { contentModerationLabels, labels } = this.ad.validationLabels;
        return contentModerationLabels.join(",");
      }
      return null;
    },
  },
  methods: {
    async load() {
      this.pending = true;
      try {
        const url = `/admin/ad/${this._id}`;
        const { data } = await this.axios({
          url,
        });
        const {
          result,
          ad,
          adMedia,
          detectPersonKeyword,
          quotaUrl,
          detectModerationKeyword,
          testSurveyUrl,
          dashboardUrl,
          ageCodes,
          answerCountCodes,
          genderCodes,
          industryCodes,
          regionCodes,
          statusCodes,
          surveyStatusCodes,
          validationStatusCodes,
          panelChannelCodes,
          questionAdds,
        } = data;
        //console.log('data:::',data);
        if (result) {
          this.ad = ad;
          if (adMedia.length) this.adMedia = adMedia;
          this.questionAdds = questionAdds;
          if (ageCodes) this.codes.ageCodes = ageCodes;
          if (answerCountCodes) this.codes.answerCountCodes = answerCountCodes;
          if (genderCodes) this.codes.genderCodes = genderCodes;
          if (industryCodes) this.codes.industryCodes = industryCodes;
          if (regionCodes) this.codes.regionCodes = regionCodes;
          if (statusCodes) this.codes.statusCodes = statusCodes;
          if (surveyStatusCodes)
            this.codes.surveyStatusCodes = surveyStatusCodes;
          if (validationStatusCodes)
            this.codes.validationStatusCodes = validationStatusCodes;
          if (detectPersonKeyword)
            this.codes.detectPersonKeyword = detectPersonKeyword;
          if (quotaUrl) this.quotaUrl = quotaUrl;
          if (detectModerationKeyword)
            this.codes.detectModerationKeyword = detectModerationKeyword;
          if (panelChannelCodes)
            this.codes.panelChannelCodes = panelChannelCodes;
          if (testSurveyUrl) this.testSurveyUrl = testSurveyUrl;
          if (dashboardUrl) this.dashboardUrl = dashboardUrl;
        }
      } catch (e) {}
      this.pending = false;
    },
    async save() {
      this.pending = true;
      try {
        const url = `/admin/ad/${this._id}`;
        const { data } = await this.axios({
          url,
          method: "PATCH",
          data: {
            ad: this.ad,
            medias: this.adMedia,
          },
        });
        const { result, ad, error } = data;
        if (result) {
          this.ad = ad;
          this.$toasted.success("변경되었습니다.", { duration: 3000 });
          this.$emit("load");
        }
      } catch (e) {}
      this.pending = false;
    },

    async surveyTestDataReset() {
      const inputString = prompt(
        '설문 TEST 데이터를 삭제하시겠습니까?\r\n삭제하시려면 "삭제합니다" 를 입력하여 주세요.'
      );
      if (inputString !== "삭제합니다") {
        this.$toasted.error(
          '데이터 삭제를 실패하였습니다.<br>삭제를 원하시면 "삭제합니다"를 올바르게 입력하여 주세요.',
          { duration: 5000 }
        );
        return;
      }

      try {
        const url = `/admin/survey/dataReset/${this._id}`;
        const { data } = await this.axios({
          url,
          method: "PATCH",
          data: this.ad,
        });
        const { result, error } = data;
        if (!result) {
          this.$toasted.error(error, { duration: 3000 });
          return;
        }

        this.$toasted.success("TEST 데이터를 삭제하였습니다.", {
          duration: 3000,
        });
      } catch (e) {}
    },
  },
};
</script>

<style scoped>
video {
  width: 100%;
  max-width: 640px;
  height: auto;
  max-height: 480px;
}
.thumb {
  width: 80%;
  height: 30rem;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
}
#edit-form {
  max-width: 1280px;
  margin: auto;
}
</style>
