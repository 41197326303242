<template>
  <div class="p-1 d-flex flex-row border-bottom">
    <div class="pr-5 mr-2 border-right align-items-center" style="width: 10%">
      {{ title }}
    </div>
    <div class="mr-5 d-flex flex-row">
      <div class="mr-2">유해</div>
      <b-form-checkbox v-model="target.harmful">
        <b-badge :variant="target.harmful ? 'success' : 'secondary'">
          {{ target.harmful ? "부적절" : "문제 없음" }}
        </b-badge>
        <b-badge variant="warning" class="mx-1">
          {{ target.validationStatus }}
        </b-badge>
      </b-form-checkbox>
    </div>
    <div class="mr-5 d-flex flex-row">
      <div class="mr-2">인물</div>
      <div class="flex-grow-1 d-flex">
        <b-form-checkbox v-model="target.character">
          <b-badge :variant="target.character ? 'success' : 'secondary'">
            {{ target.character ? "인물 있음" : "인물 없음" }}
          </b-badge>
        </b-form-checkbox>
      </div>
    </div>
    <div class="mr-5 d-flex flex-row">
      <div class="mr-2">음악</div>
      <div class="flex-grow-1 d-flex">
        <b-form-checkbox v-model="target.music">
          <b-badge :variant="target.music ? 'success' : 'secondary'">
            {{ target.music ? "음악 있음" : "음악 없음" }}
          </b-badge>
        </b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "validation",
  props: {
    title: String,
    target: {
      harmful: Boolean,
      validationStatus: String,
      character: Boolean,
      music: Boolean,
    },
  },
};
</script>

<style scoped></style>
